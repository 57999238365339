import { DisplayType } from '@shared/types/enums';
import { GridColumn, GridRow } from '../Grid/Grid';
import SearchMobileModal from '../Modal/SearchMobileModal/SearchMobileModal';
import PassengerCounter, {
  Props as PassengengerCounterProps,
} from './PassengerCounter';

export type Props = {
  onCloseModal: () => void;
  title: string;
} & PassengengerCounterProps;

export const PassengerCounterModal = ({
  onCloseModal,
  title,
  ...props
}: Props) => {
  return (
    <SearchMobileModal
      id="passsenger-counter-modal"
      onCloseModal={onCloseModal}
      title={title}
    >
      <GridRow>
        <GridColumn
          pt={[
            DisplayType.ExtraExtraSmall,
            DisplayType.ExtraSmall,
            DisplayType.Small,
          ]}
          width={[12]}
        >
          <PassengerCounter {...props} />
        </GridColumn>
      </GridRow>
    </SearchMobileModal>
  );
};

export default PassengerCounterModal;
