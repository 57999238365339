import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { mqMin } from '../../../styles/base';
import Button, { ButtonType } from '../../Button/Button';
import StepNavigation from '../../Steps/StepNavigation';
import Modal from '../Modal';

export interface Props {
  children: React.ReactNode;
  className?: string;
  id: string;
  onCloseModal: () => void;
  title?: string;
}

const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
  fill: #fff;
  transform: rotate(180deg);
`;

const StyledModal = styled(Modal)`
  display: block;

  ${mqMin.Small} {
    display: none;
  }
`;

const SearchMobileModal = ({
  children,
  className,
  id,
  onCloseModal,
  title,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StyledModal
      ariaLabel={title}
      className={className}
      disableOutsideClick
      id={id}
      onClose={onCloseModal}
    >
      <StepNavigation
        leftContent={
          <Button buttonType={ButtonType.RAW} onClick={onCloseModal}>
            <StyledArrowIcon />
            <span className="aux">{t('Close')}</span>
          </Button>
        }
        title={title}
      />
      {children}
    </StyledModal>
  );
};

export default SearchMobileModal;
