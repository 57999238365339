import SearchMobileModal from '../Modal/SearchMobileModal/SearchMobileModal';
import DatePicker, { Props as DatePickerProps } from './DatePicker';

export type Props = {
  onCloseModal: () => void;
  title: string;
} & DatePickerProps;

const DatePickerModal = ({ onCloseModal, title, ...props }: Props) => {
  return (
    <SearchMobileModal
      id="date-picker-modal"
      onCloseModal={onCloseModal}
      title={title}
    >
      <DatePicker {...props} />
    </SearchMobileModal>
  );
};

export default DatePickerModal;
